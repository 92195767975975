<template>
  <v-form @submit.prevent="$emit('save', product)" autocomplete="off">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="product.product_group_id"
              :items="allowedForNewItemsProductGroups"
              :error-messages="errors['product_group_id']"
              :label="formMixin_getRequiredFieldLabel($t('product_group_id'))"
              :disabled="!!product.id"
              item-value="id"
              item-text="title"
              clearable
              :prepend-icon="!product.id ? 'mdi-magnify' : ''"
              @input="formMixin_clearErrors('product_group_id')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="product.title_lt"
              :error-messages="errors['title_lt']"
              :label="formMixin_getRequiredFieldLabel($t('title_lt'))"
              @input="formMixin_clearErrors('title_lt')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="product.title_en"
              :error-messages="errors['title_en']"
              :label="formMixin_getRequiredFieldLabel($t('title_en'))"
              @input="formMixin_clearErrors('title_en')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model.number="product.price"
              :error-messages="errors['price']"
              :label="formMixin_getRequiredFieldLabel($t('price'))"
              type="number"
              @input="formMixin_clearErrors('price')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-checkbox
              v-model="product.is_hidden"
              :label="$t('is_hidden')"
              :error-messages="errors.is_hidden"
              @change="formMixin_clearErrors('is_hidden')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!product.id" class="text-subtitle-2 ml-3"> * {{ $t('must_be_filled') }}</span>

        <v-spacer />

        <v-btn text color="primary" @click="$emit('cancel')">{{ $t('cancel') }}</v-btn>

        <v-btn :disabled="disabled" :loading="disabled" color="primary" text type="submit">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import formMixin from '@/mixins/form-mixin';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'ProductForm',

  components: {},

  mixins: [formMixin],

  props: {
    product: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('products', ['productGroups']),
    ...mapGetters('products', ['productGroupsSelect']),

    allowedForNewItemsProductGroups() {
      const groups = [];
      for (let i = 0; i < this.productGroupsSelect.length; i++) {
        if (!this.product.id) {
          if (this.productGroupsSelect[i].allowed_for_new_items) {
            groups.push(this.productGroupsSelect[i]);
          }
        } else {
          groups.push(this.productGroupsSelect[i]);
        }
      }
      return groups;
    },

    formTitle() {
      return this.$t(this.product.id ? 'edit_product' : 'new_product');
    },
  },
};
</script>

<style scoped></style>
