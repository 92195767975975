/* eslint-disable no-param-reassign */
const formMixin = {
  methods: {
    formMixin_clearErrors(field) {
      if (!this.errors[field]) {
        return;
      }
      this.$emit('clear:errors', field);
    },

    formMixin_collectMultiSelectErrors(field) {
      let errors = [];
      Object.keys(this.errors).forEach((key) => {
        if (key.indexOf(field) > -1) {
          errors = errors[field] ? [...errors[field], ...this.errors[key]] : this.errors[key];
        }
      });
      if (errors.length) {
        this.$set(this.errors, field, errors);
      }
    },

    formMixin_getRequiredFieldLabel(label) {
      return `${label}*`;
    },

    // Used when BaseAutocomplete.vue component emits a value
    formMixin_setAutocompleteValue(res, formItem, objectField, idField) {
      if (!formItem) {
        return;
      }
      const id = idField || `${objectField}_id`;
      if (res) {
        formItem[objectField] = res;
        formItem[id] = res.id;
      } else {
        formItem[objectField] = null;
        formItem[id] = null;
      }
    },
  },
};

export default formMixin;
